/*
Copyright 2021 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

export default ({ logger }) =>
  (adobeEdgeHeader) => {
    if (adobeEdgeHeader) {
      const headerParts = adobeEdgeHeader.split(";");
      if (headerParts.length >= 2 && headerParts[1].length > 0) {
        try {
          const regionId = parseInt(headerParts[1], 10);
          if (!Number.isNaN(regionId)) {
            return { regionId };
          }
        } catch {
          // No need to do anything. The log statement below will log an error
        }
      }
      logger.warn(`Invalid adobe edge: "${adobeEdgeHeader}"`);
    }
    return {};
  };
